.one-col-text,
.two-col-text {

	@apply w-full relative bg-white;

	&__wrapper {
		@apply border-t border-ltGrey border-solid;

		@screen lg {
			@apply flex;
		}
	}

	&__module {
		@screen lg {
			@apply pr-6 w-1/2;
		}

		@screen xxl {
			@apply pr-10;
		}
	}

	.container {
		@apply relative z-10;
	}

	.graphic-device-right,
	.graphic-device-left {
		@apply hidden;
	}

	&.column-right {
		.one-col-text__wrapper { 
			@apply flex-row-reverse;

			.one-col-text__module {
				@screen lg {
					@apply pl-6 pr-0;
				}

				@screen xxl {
					@apply pl-10 pr-0;
				}
			}
		}
	}
}

.graphic-device {
	@apply relative;

	.one-col-text__wrapper {
		@apply border-none;
	}

	&.column-left {
		.graphic-device-right {
			@apply block absolute top-0 right-0;
			width: 160px;
			height: 160px;

			@screen lg {
				width: 342px;
				height: 342px;
			}
		}
	}

	&.column-right {
		.graphic-device-left {
			@apply block absolute top-0 left-0;
			width: 160px;
			height: 320px;

			@screen lg {
				width: 300px;
				height: 600px;
			}

			@screen xl {
				width: 400px;
				height: 800px;
			}
		}
	}
}

.two-col-text {

	&__wrapper {
		@screen lg {
			@apply block;
		}
	}

	&__module {
		&:first-child {
			@screen lg {
				@apply pr-6 pl-0 w-1/2;
			}

			@screen xxl {
				@apply pr-10 pl-0;
			}
		}
		&:last-child {
			@screen lg {
				@apply pl-6 pr-0 w-1/2;
			}

			@screen xxl {
				@apply pl-10 pr-0;
			}
		}
	}

	&__module-top-title {
		@apply w-full;

		@screen lg {
			@apply pr-6 pl-0 w-1/2;
		}

		@screen xxl {
			@apply pr-10 pl-0;
		}
		
	}
}