.carousel {

	&__wrapper {
		@apply border-t border-ltGrey border-solid;
	}

	.swiper-button-next, 
	.swiper-button-prev {

		@apply relative text-black text-base;
		width: 114px;
		height: 34px; 

		&:after {
			@apply hidden;
		}

		@screen xl {
			@apply text-lg;
		}

		svg {
            @apply transition-transform duration-300 ease-in-out;
        }

        &:hover {
           svg {
                transform: translateX(10px);
           }
        }

	}

	.swiper-button-next {
		&:hover {
           svg {
                transform: translateX(10px);
           }
        }
	}

	.swiper-button-prev { 
		span {
			@apply rotate-180;
		}

		&:hover {
           svg {
                transform: translateX(-10px);
           }
        }
	}

}

.dark + .carousel .carousel__wrapper {
	@apply border-none;
}