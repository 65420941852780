.solutions {
	&__sphere {
		width: 130px;
		@apply absolute top-0 right-0 hidden;

		@screen lg {
			width: 314px;
		}

		@screen xl {
			width: 414px;
		}
	}

	&[data-scroll="in"] {
		.solutions__sphere {
			@apply block;
		}
	}
}