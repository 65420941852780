.home {
	.header {
		@apply bg-violet;

		&__logo-dark {
			@apply hidden;
		}

		&__logo-white {
			@apply block;
		}

		&__phone {
			@apply text-white;
		}

		&__primary-sub-list { 
			@apply bg-violet;
		}

		&__primary-list {

			.list-item {
				a {
					@apply text-white;

					&.active {
						&:before {
							@apply bg-white;
						}
					}

					&:hover {
						@apply bg-white text-violet;
					}
				}

				&:hover {
					> a {
						@apply bg-white text-violet;
					}
				}
			}
		}
	}
}

.header {
	@apply bg-white mx-auto relative z-50 py-6;

	@screen xl {
		@apply py-6;
	}

	&__logo-dark {
		@apply block;
		width: 177px;
		height: 48px;
		transform: translateX(-18px);

		@screen xl {
			width: 192px;
			height: 52px;
			transform: translateX(-20px);
		}
	}

	&__logo-white {
		@apply hidden;
		width: 177px;
		height: 48px;
		transform: translateX(-18px);

		@screen xl {
			width: 192px;
			height: 52px;
			transform: translateX(-20px);
		}
	}

	&__phone {
		@apply text-black;	
	}

	&__primary-sub-list {
		@apply opacity-0 absolute bg-white left-0 z-10 top-0 mt-9 invisible border-ltGrey border-solid border px-4 py-5;
		min-width: 300px;
		transform: translateY(16px);
		transition: opacity 0, transform 0;

		li {
			@apply my-2;
		}
	}

	&__primary-list {
		@apply flex items-center justify-end;

		.list-item {
			@apply text-lg ml-5 relative py-4;

			a {
				@apply text-black block relative px-2.5 py-1 rounded-full;

				&.active {
					&:before {
						content: "";
						transform: translateX(-50%);
						@apply absolute w-2 h-2 rounded-full bg-black left-2/4 bottom-0 -mb-5;
					}
				}

				&:hover {
					@apply bg-black text-white;
				}
			}

			&:hover {
				> a {
					@apply bg-black text-white;
				}
				.header__primary-sub-list {
					@apply opacity-100 visible;
					transform: translateY(24px);
					transition: opacity 0.3s, transform 0.3s;

					a.active {
						@apply bg-black text-white;
						&:before {
							@apply hidden;
						}
					}
				}
			}
		}
	}
}