.cta {
	@apply relative z-10;
	
	&__globe {
		width: 280px;
		@apply absolute top-0 right-0 -mr-8 -mt-16;

		@screen xl {
			width: 635px;
			@apply -mt-28;
		}
	}

	&[data-scroll="in"] {
		.cta__globe {
			@apply block;
		}
	}
}