.method {
	&__sphere {
		width: 260px;
		height: 260px;
		@apply absolute bottom-0 left-0 bg-violet rounded-r-full overflow-hidden;
		transform: translate(-50%, 50%);

		@screen lg {
			width: 668px;
			height: 668px;
		}

		@screen xl {
			width: 868px;
			height: 868px;
		}
	}

	&__overlay {
		@apply absolute left-2/4 z-10;
		width: 40%;
		bottom: 130px;

		@screen lg { 
			bottom: 334px;
			width: 37%;
		}

		@screen xl { 
			bottom: 434px;
			width: 37%;
		}
	}

	&__sphere-container {
		@apply relative w-full h-full;
	}

	&__sphere-container-color {
		@apply absolute rounded-full;

		width: 260px;
		height: 260px;

		left: 0;
		top: 0;
		animation: spin 30s ease-in-out infinite;

		@screen lg { 
			@apply rounded-none;
			top: 0%;
			left: 0%;
			width: 668px;
			height: 668px;
		}

		@screen xl { 
			width: 868px;
			height: 868px;
		}
	}

	.c1 {
		background: rgba(255,159,85,0.8);
	}

	.c2 {
		background: radial-gradient(circle at top right, rgba(133,224,237,0.4) 30%, rgba(133,224,237,0) 70%);
		animation: spin 29s ease-in-out infinite;
	}

	.c3 {
		background: radial-gradient(circle at top left, rgba(200,133,163,0.8) 30%, rgba(200,133,163,0) 70%);
		animation: spin 41s ease-in-out infinite;
	}

	@keyframes spin { 
		0% { transform: rotate(0deg); }
		20% { transform: rotate(-90deg); }
		40% { transform: rotate(60deg); }
		60% { transform: rotate(-180deg); }
		80% { transform: rotate(-270deg); }
		100% { transform: rotate(0deg); }
	}
}