.form-group,
.form-label,
.form-input {
  @apply relative inline-block w-full;
}

.form {
  &__sphere {
    width: 130px;
    @apply absolute top-0 left-0;

    @screen lg {
      width: 342px;
    }
  }
}

label { 
	@apply mb-6;

	@screen lg {
			@apply mb-8;
	}

	.label {
		@apply text-white text-base font-TWKReg block mb-1.5;
	}
}


input[type=text],
input[type=email],
input[type=tel],
input[type=tel],
.form-select,
textarea {
	@apply px-4 py-2 text-black text-lg border-none bg-ltGrey font-TWKReg;
	border-radius: 0px !important;

	&:focus,
	&:valid {
		@apply bg-white outline-none shadow-none ring-0;
	}
}


textarea {
	&[value=""] {
		@apply px-4 py-2 text-black text-lg border-none bg-ltGrey font-TWKReg;
	}
	&:focus,
	&:not([value=""]) {
		@apply bg-white outline-none shadow-none ring-0;
	}
}

.error {
	@apply text-error text-sm pt-2;
}

.file-upload {
	.inputfile {
		width: .1px;
		height: .1px;
		z-index: -1;
		@apply opacity-0 overflow-hidden absolute;

		&:valid + .button + .success { 
			@apply inline-block;
		}
	}

	.success {
			@apply hidden text-white font-TWKReg pt-1;
	}

	.upload-btn {
		@apply border-2 border-dashed border-black text-black bg-ltGrey w-full flex items-center justify-center text-center px-4 py-4 text-lg;

		&:hover {
			@apply bg-white;
		}
	}
}

 .ff-errors {
 	li {
		@apply text-error text-sm pt-2;
 	}
  }

 .freeform-form-errors, .freeform-errors {
	 li {
		 @apply text-error;
	 }
 }
  
.alert--success p {
	color: #ffffff !important;
}
