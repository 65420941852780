.img-text {

	@apply w-full relative bg-white;

	&__wrapper {
		@screen lg {
			@apply flex;
		}
	}

	&__img-module {
		@apply mb-6;

		@screen lg {
			@apply px-6 w-1/2 mb-0;
		}

		@screen xxl {
			@apply px-10;
		}

		a, div {
			@apply block relative h-full;

			.img-top,
			.img-bottom {
				@apply absolute opacity-0 transition-all duration-500;
			}

			.img-top {
				@apply top-0 right-0 z-10;
			}

			.img-bottom {
				@apply bottom-0 left-0 z-30;
			}

			&:hover {
				.img-top {
					@screen lg {
						@apply opacity-100;
						transform: translate(24px, -24px);
					}

					@screen lg {
						transform: translate(40px, -40px);
					}
				}
				.img-bottom {
					@screen lg {
						@apply opacity-100;
						transform: translate(-24px, 24px);
					}

					@screen lg {
						transform: translate(-40px, 40px);
					}
				}
			}
		}
	}

	&__text-module {
		@screen lg {
			@apply pl-6 w-1/2;
		}

		@screen xxl {
			@apply pl-10;
		}
	}

	.container {
		@apply relative z-10;
	}

	&.img-right {
		.img-text__wrapper { 
			@apply flex-row-reverse;

			.img-text__text-module {
				@screen lg {
					@apply pr-6 pl-0;
				}

				@screen xxl {
					@apply pr-10 pl-0;
				}
			}
		}
	}
}
