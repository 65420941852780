.statement {

	@apply w-full overflow-hidden bg-white;

	@screen lg {
		@apply flex items-center;
	}

	&__sphere {
		width: 310px;
		height: 310px;
		transform: translate(-35%, -50%);
		@apply absolute left-2/4 top-2/4 hidden opacity-20 hidden;

		@screen lg {
			transform: translate(-50%, -50%);
		}

		@screen xl {
			width: 534px;
			height: 534px;
		}
	}

	&.graphic-device {
		min-height: 310px;

		@screen xl { 
			min-height: 534px;
		}

		.statement__wrapper {
			@apply pt-20 pb-16;

			@screen lg { 
				@apply py-20;
			}
		}
		.statement__sphere {
			@apply block;
		}
	}

	&.dark {
		.statement__sphere {
			@apply opacity-100;
		}
	}

	&[data-scroll="in"] {
		.statement__sphere {
			@apply block;
		}
	}
}

