
.breadcrumbs {
    @apply flex flex-wrap justify-start mb-6;

    @screen lg {
        @apply mb-8;
    }

    &__item {
        @apply hidden;

        @apply text-xl;

        @screen lg { 
            @apply block border-l border-black border-solid pl-4 ml-4;
        }

        &:first-child {
            @screen lg { 
                @apply border-none pl-0 ml-0;
            }
        }

        &:last-child {
            @apply block; 
        }

        @screen xl {
            @apply text-2xl;
        }
    }

    &__link {

    }

    &__link--active {

        @apply flex items-center justify-start;

        @screen lg {
        }

        svg {
            @screen lg {
                @apply hidden;
            }
        }
    }

}