.four-col {

	&__module {
		@apply mb-6;

		@screen sm {
			@apply px-3 w-1/2;
		}

		@screen md {
			@apply px-5;
		}

		@screen lg {
			@apply px-6;
		}

		@screen xl {
			@apply px-8 w-1/4 mb-12;
		}
		
		.h3 a {
			&:hover {
				@apply opacity-50;
			}
		}
	}
}