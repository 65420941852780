/* purgecss start ignore */

	@import "global/header";
	@import "global/footer";
	@import "global/custom";
	@import "global/typography";
	@import "global/animations";
	
	@import "sections/solutions";
	@import "sections/method";
	@import "sections/statement";
	@import "sections/cta";
	@import "sections/carousel";
	@import "sections/testimonials";
	@import "sections/home-banner";
	@import "sections/column-text";
	@import "sections/img-text";
	@import "sections/four-col";
	@import "sections/platform-logos";

	@import "sections/sections";

	@import "partials/breadcrumbs";
	@import "partials/forms";

	
/* purgecss end ignore */