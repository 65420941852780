.home-banner {
	@apply h-screen flex flex-col justify-center relative pb-12;
	min-height: 520px;

	@screen lg {
		min-height: 826px;
		max-height: 886px;
		@apply pb-24;
	}

	@screen xl { 
		min-height: 958px;
		max-height: 118px;
		@apply pb-32;
	}

	&__animation-wrapper {
		max-width: 874px;

		@screen lg {
			@apply mt-32;
		}

		@screen xl {
			@apply mt-48;
		}
	}

	&__sml {
		@apply w-2/3 max-w-md;
	}

	&__arrow {
		@apply mx-auto absolute bottom-0 left-2/4;
		transform: translateX(-50%);

		@screen lg {
			@apply hidden;
		}
	}
}