// Typography

.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
     @apply font-TWKReg; 
}


@responsive {
    .h1,
    .wysiwyg h1 {
        font-size: 40px;
        @apply leading-none;
       
        @screen lg { 
           font-size: 60px;
        }

        @screen xl { 
            font-size: 80px;
        }
    }

    .h2,
    .wysiwyg h2 {
        @apply text-2xl;

        @screen xl { 
            @apply leading-snug;
            font-size: 44px;
        }

        &.sml-mob {
            @apply text-xl;

             @screen xl { 
                @apply leading-snug;
                font-size: 44px;
            }
        }

        &.sml {
            @apply text-xl;

            @screen xl {
                @apply text-4xl leading-snug;
            }
        }
    }

    .h3,
    .wysiwyg h3 {
        font-size: 22px;
        @apply leading-relaxed;

        @screen xl { 
            font-size: 40px; 
            @apply leading-snug;
        }

        &.sml {
            @screen xl { 
                font-size: 32px; 
                @apply leading-snug;
            }
        }
    }

    .h4,
    .wysiwyg h4 {
       
        @apply leading-none text-xl;

        @screen xl {  
            @apply text-4xl;
        }
    }

    .h5,
    .wysiwyg h5 {
        @apply text-xl;

        @screen xl { 
            font-size: 32px;
            @apply leading-snug;
        }
    }

    .h6,
    .wysiwyg h6 {
        @apply text-xl;

        @screen xl {
            font-size: 28px;
            @apply leading-snug;
        }
    }
}

p, ul, ol, dl, blockquote, pre, dialog {
    @apply font-TWKReg;
}

p {
   @apply mb-4 text-base leading-snug;

    @screen lg {
        @apply text-lg mb-6;
    }

    strong {
        @apply font-TWKBold;
    }

    &:last-child {
        @apply mb-0;
    }

    &.caption {
        @apply text-sm text-grey uppercase;

        @screen lg { 
            @apply text-base;   
        }

        span {
            @apply text-black;
        }
    }

    &.sml {
        @apply text-sm;

        @screen lg { 
            @apply text-base;   
        }
    }

    a {
        @apply underline;

        &:hover {
            @apply no-underline;
        }
    }
}

.tags {
    @apply flex justify-start flex-wrap;

    li {
        @apply text-base text-black px-3 py-0.5 rounded-full mr-1.5 mt-2;
    }

    &__industry {
        @apply bg-pink;
    }

    &__solution {
        @apply bg-ltBlue;
    }
}

.lead {
    p {
        @apply text-lg leading-normal;

        @screen xl {
            font-size: 28px;
        }
    }
}

button,
.button,
.wysiwyg .button,
input[type=submit] {
    transition: all 0.3s;
    @apply cursor-pointer inline-block whitespace-nowrap font-TWKReg;
    box-sizing: border-box;

    &:hover,
    &:active,
    &:focus {
        @apply cursor-pointer;

    }

    &.primary {
        @apply inline-flex rounded-full border-black border-solid border relative py-2 px-6 text-base text-black justify-center items-center leading-tight;
        span  {
            @apply mr-4;
        }
        @screen xl {
            @apply text-lg;
        }

        &:hover {
            @apply bg-black text-white;
        }
    }

    &.secondary {
        @apply inline-flex relative py-2 text-base text-black justify-center items-center leading-tight;

        span  {
            @apply mr-4;
        }

        @screen xl {
            @apply text-lg;
        }

        svg {
            @apply transition-transform duration-300 ease-in-out;
        }

        &:hover {
           svg {
                transform: translateX(10px);
           }
        }
    }

    &.tertiary {
        @apply inline-flex relative text-base text-black justify-center items-center leading-tight;

        span  {
            @apply mr-4;
        }

        @screen xl {
            @apply text-lg;
        }

        svg {
            @apply transition-transform duration-300 ease-in-out;
        }

        &:after {
            content: "";
            transition: all .5s cubic-bezier(1,0,.29,1) .1s;
            @apply absolute h-px bg-black right-0 w-full opacity-0 bottom-0 -mb-1;
        }

        &:hover { 
            &:after {
               @apply opacity-100;
            }
        }
    }


    &[disabled=disabled], 
    &:disabled {
        opacity: 0.3;
        cursor: default;
    }

    &:focus {
        outline: none;
    }
}

.dark {
    .primary.button { 
        @apply border-white text-white;

        &:hover {
            @apply bg-white text-violet;
        }
    }
    .secondary.button { 
        @apply text-white;
    }
    .tertiary.button { 
        @apply text-white;

        &:after {
            content: "";
            transition: all .5s cubic-bezier(1,0,.29,1) .1s;
            @apply absolute h-px bg-white right-0 w-full opacity-0 bottom-0 -mb-1;
        }

        &:hover { 
            &:after {
               @apply opacity-100;
            }
        }
    }
  
}

.link {
    @apply relative;

    &:after {
        content: "";
        transition: all .5s cubic-bezier(1,0,.29,1) .1s;
        @apply absolute h-px bg-white right-0 w-full opacity-0 bottom-0;
    }

    &:hover { 
        &:after {
           @apply opacity-100;
        }
    }
}

.wysiwyg {

    h1,h2,h3,h4, h5, h6 {
        
        @apply mb-4 mt-6;

        &:first-child {
            @apply mt-0;
        }

        @screen lg {
            @apply mb-6 mt-8;
        }
    }

    ul {
        li {
            @apply border-b py-2 border-solid border-black text-base;

            @screen xl {
                @apply text-lg py-2.5;
            }

            a {
                @apply block flex items-center justify-between;

                svg {
                    @apply mr-4 transition-transform duration-300;
                }

                &:hover {
                    svg {
                        transform: translateX(16px);
                    }
                }
            }
        }
    }

    figure {
        @apply mb-2;
    }
  
}


.dark {
    .h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
        @apply text-white; 
    }

    p {
        @apply text-ltGrey;
    }

    &.footer p {
        @apply text-white;
    }

    .caption__author {
        @apply text-white;
    }

    .wysiwyg {
        ul {
            li {
                @apply border-white text-white;

                a {
                    @apply text-white;
                }
            }
        }
    }
}