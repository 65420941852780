.platform-logos {

	&__module {
		@apply w-1/2 px-3 -mt-px;

		@screen sm { 
			@apply w-1/3;
		}

		@screen md {
			@apply px-5;
		}

		@screen lg {
			@apply px-6 w-1/5;
		}

		@screen xl {
			@apply px-8;
		}
	
	}
}