.dark + .footer {
	.footer__wrapper {
		@apply border-t border-solid border-ltGrey;
	}
}

.footer {

	&__primary-list {
		@apply flex flex-wrap items-center -ml-2.5;

		> .list-item {
			@apply w-1/2 text-lg;

			&:nth-child(odd) {
				@apply pr-6;
			}

			@screen lg {
				@apply w-auto pr-2;

				&:nth-child(odd) {
					@apply pr-2;
				}
			}

			@screen xl { 
				@apply w-auto pr-4;

				&:nth-child(odd) {
					@apply pr-4;
				}
			}

			&:last-child {
				@screen lg {
					@apply pr-0;
				}
			}

			a {
				@apply rounded-full px-2.5 py-0.5;

				&:hover {
					@apply bg-white text-violet;
				}
			}
		}

		a {
			@apply text-white inline-block py-2;
		}
	}

	&__secondary-list {

		@screen lg { 
			@apply flex justify-end items-start;
		}

		> .list-item {
			@apply text-sm text-white py-2;

			@screen lg {  
				@apply pl-4 pt-0;
			}
		}

		a {
			@apply text-white inline-block;

			&.phone {
				@apply inline-flex;
			}
		}
	}
}