
@responsive {
	.pt-section {
		@apply pt-10;

		@screen lg {
			@apply pt-24;
		}

		@screen xl {
			@apply pt-32;
		}
	}

	.pb-section {
		@apply pb-10;

		@screen lg {
			@apply pb-24;
		}

		@screen xl {
			@apply pb-32;
		}
	}

	.mt-section {
		@apply mt-10;

		@screen lg {
			@apply mt-24;
		}

		@screen xl {
			@apply mt-32;
		}
	}

	.mb-section {
		@apply mb-10;

		@screen lg {
			@apply mb-24;
		}

		@screen xl {
			@apply mb-32;
		}
	}
}

