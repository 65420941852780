.testimonials {

	&__sphere-mob {
		width: 280px;
		height: 140px;
		@apply mx-auto -mt-10 mb-16 rounded-b-full bg-violet overflow-hidden;

		@screen lg {
			@apply hidden;
		}
	}

	&__sticky-wrapper {
		@apply w-1/2 h-full absolute;
		top: 280px;
	}

	&__sphere-dt {
		@apply hidden;
		@screen lg {
			@apply block sticky left-0 bg-violet rounded-r-full overflow-hidden;
			top: 180px;
			width: 348px;
			height: 696px;
		}

		@screen xl { 
			width: 448px;
			height: 896px;
		}
	}

	&__sphere-container {
		@apply relative w-full h-full;
	}

	&__sphere-container-color {
		@apply absolute rounded-full;

		width: 280px;
		height: 280px;


		left: 0;
		top: -100%;
		animation: spin 30s ease-in-out infinite;

		@screen lg { 
			@apply rounded-none;
			top: 0%;
			left: -100%;
			width: 696px;
			height: 696px;
		}

		@screen xl { 
			width: 896px;
			height: 896px;
		}
	}

	&__sticky-title {
		@apply mb-10;

		@screen lg { 
			@apply sticky;
			top: 20px;
		}

		@screen xl {
			top: 60px;
		}
	}

	&__wrapper {
		@apply border-t border-ltGrey border-solid;
	}

	&__module {
		@apply pb-8 border-b border-ltGrey border-solid mb-8;

		@screen xl {
			@apply pb-10 mb-16;
		}

		&:last-child {
			@apply mb-0;
		}

		.lead {
			@apply mb-6;

			@screen xl {
				@apply mb-10;
			}

			&:last-child {
				@apply mb-0;
			}
		}

		.button-wrap {
			@apply mb-4;

			@screen xl {
				@apply mb-8;
			}

			&:last-child {
				@apply mb-0;
			}
		}
	}

	.c1 {
		background: rgba(255,159,85,0.8);
	}

	.c2 {
		background: radial-gradient(circle at top right, rgba(133,224,237,0.4) 30%, rgba(133,224,237,0) 70%);
		animation: spin 29s ease-in-out infinite;
	}

	.c3 {
		background: radial-gradient(circle at top left, rgba(200,133,163,0.8) 30%, rgba(200,133,163,0) 70%);
		animation: spin 41s ease-in-out infinite;
	}

	@keyframes spin { 
		0% { transform: rotate(0deg); }
		20% { transform: rotate(-90deg); }
		40% { transform: rotate(60deg); }
		60% { transform: rotate(-180deg); }
		80% { transform: rotate(-270deg); }
		100% { transform: rotate(0deg); }
	}

}

.dark + .testimonials .testimonials__wrapper {
	@apply border-none;
}