.container {
	max-width: 1608px !important;

	&-l {
		max-width: 1920px !important;
	}
}

.padding-x {
	@apply px-6;
}

.max-width {
	@apply max-w-2xl mx-auto;

	@screen lg {
		@apply max-w-none;
	}
}

.btn-wrap {
	@apply mt-6 flex flex-col items-start;

	@screen xl { 
		@apply flex-row items-center mt-10;
	}
	.button {
		&:nth-child(2) {
			@apply mt-4;

			@screen xl {  
				@apply ml-6 mt-0;
			}
		}
	}
}

footer.dark,
section.dark {
	@apply bg-violet;

}